import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/en/Layout"
import Header from "../../components/en/Header"
import WaltzBlock from "../../components/en/WaltzBlock"
import Footer from "../../components/en/Footer"

class WaltzBlockPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | Waltz Block</title>
          <meta
            name="description"
            content="Nešto lepo se dešava kada se proba Waltz block. Slatka tabla koja mami osmeh i traži reč više kada god da se ugrize. Kupite je."
          />
          <meta name="image" content="https://waltz.rs/waltz_blok_seo.jpg" />
          <meta itemprop="name" content="WALTZ | Waltz Block" />
          <meta
            itemprop="description"
            content="Nešto lepo se dešava kada se proba Waltz block. Slatka tabla koja mami osmeh i traži reč više kada god da se ugrize. Kupite je."
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/waltz_blok_seo.jpg"
          />
          <meta name="og:title" content="WALTZ | Waltz Block" />
          <meta
            name="og:description"
            content="Nešto lepo se dešava kada se proba Waltz block. Slatka tabla koja mami osmeh i traži reč više kada god da se ugrize. Kupite je."
          />
          <meta name="og:image" content="https://waltz.rs/waltz_blok_seo.jpg" />
          <meta name="og:url" content="https://waltz.rs/waltz-block" />
          <meta name="og:site_name" content="WALTZ | Waltz Block" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <WaltzBlock />
        <Footer />
      </Layout>
    )
  }
}
export default WaltzBlockPage
