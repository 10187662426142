import React from "react"

import waltz_blok from "../../images/products/waltz-blok.jpg"

class WaltzBlock extends React.Component {
  render() {
    return (
      <section id="proizvodi" className="hero products-page default-page">
        <div className="container padding-0 margin-0">
          <div className="columns margin-0 main">
            <div className="column is-4 static-part is-left padding-0">
              <div className="content">
                <div className="title">WALTZ BLOCK</div>
                <span className="zigzag"></span>
                <p className="text">
                  Something special happens when you taste a Waltz block. A
                  sweet board that lures a smile and asks for a word more
                  whenever it bites. Buy it.
                </p>
              </div>
            </div>
            {/*STATIC PART END */}
            <div className="column is-8 content-part is-right padding-0">
              <div className="columns is-multiline inner margin-0">
                <div className="column is-12 single-product padding-0">
                  <img src={waltz_blok} alt="Waltz blok" />
                </div>
              </div>
            </div>
            {/* PRODUCTS PART END */}
          </div>
          {/* COLUMNS END */}
        </div>
        {/* CONTAINER END */}
      </section> /* PRODUCTS PAGE SECTION END */
    )
  }
}

export default WaltzBlock
